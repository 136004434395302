<template>
  <section>
    <b-breadcrumb
      :items="breadcrumb"
    />
    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <validation-observer ref="rules">
            <b-row>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-group
                    label="Status"
                    label-for="status"
                  >
                    <b-form-select
                      id="status"
                      v-model="status"
                      :options="massEmailStatusOptions"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-group
                    label="Assunto"
                    label-for="subject"
                  >
                    <b-form-input
                      id="subject"
                      v-model="subject"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row class="text-right">
            <b-col
              class="form-buttons"
              style="margin-top: 20px"
            >
              <b-button
                variant="outline-secondary"
                @click="reset()"
              >
                Limpar
              </b-button>
              <b-button
                variant="gradient-primary"
                @click="getMassEmails()"
              >
                Buscar
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h2 v-if="isSubmitted">
                Envios em massa
              </h2>
            </div>
            <div class="text-right">
              <b-button
                variant="gradient-primary"
                @click="newMassEmail"
              >
                Novo
              </b-button>
            </div>
          </div>
          <div v-if="isSubmitted">
            <b-table
              :busy="isLoading"
              fixed
              :items="results"
              :fields="result_fields"
              sort-icon-left
              no-local-sorting
              :sort-by.sync="filtersValues.sortBy"
              :sort-desc.sync="filtersValues.sortDesc"
              class="mt-1"
              @sort-changed="sortingChanged"
            >
              <template #table-busy>
                <div class="text-center table-spinner">
                  <b-spinner label="Loading..." />
                  <strong>Carregando...</strong>
                </div>
              </template>

              <template #cell(status)="row">
                <b-badge
                  :variant="{
                    'editing': 'secondary',
                    'awaiting': 'info',
                    'sending': 'warning',
                    'sent': 'success',
                  }[row.item.status]"
                >
                  {{ $t(`mass_email.status.${row.item.status}`) }}
                </b-badge>
              </template>

              <template #head(subject)>
                Assunto
              </template>

              <template #head(updated_at)>
                Última atualização
              </template>
              <template #cell(updated_at)="row">
                {{ formatDateTimeDbToView(row.item.updated_at) }}
              </template>

              <template #head(actions)>
                Ações
              </template>
              <template #cell(actions)="row">
                <div class="d-flex">
                  <b-button
                    variant="secondary"
                    @click="open(row.item.id)"
                  >
                    <feather-icon
                      icon="EditIcon"
                    />
                  </b-button>

                  <b-button
                    variant="secondary"
                    class="ml-1"
                    @click="remove(row.item.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                    />
                  </b-button>
                </div>
              </template>
            </b-table>

            <b-row v-if="!isLoading && isSubmitted">
              <b-col cols="6">
                <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
              </b-col>

              <b-col cols="6">
                <!-- pagination nav -->
                <b-pagination-nav
                  v-model="currentPage"
                  :number-of-pages="pages.last_page"
                  base-url="#"
                  align="end"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BButton,
  BCard,
  BBadge,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BRow,
  BSpinner,
  BPaginationNav,
  BTable,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  massEmailStatusOptions,
} from '@/utils/options'
import MassEmailService from '@/services/massEmailService'
import toast from '@/mixins/toast'
import { formatDateTimeDbToView } from '@/utils/helpers'

export default {
  name: 'MassEmailsList',
  components: {
    BCardActions,
    ValidationProvider,
    ValidationObserver,
    BBreadcrumb,
    BButton,
    BCard,
    BBadge,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BRow,
    BSpinner,
    BPaginationNav,
    BTable,
  },
  mixins: [
    toast,
  ],
  data() {
    return {
      breadcrumb: [
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Emails',
          active: true,
        },
        {
          text: 'Envio em massa',
          active: true,
        },
      ],
      status: '',
      subject: '',
      filtersValues: {
        sortBy: 'id',
        sortDesc: true,
      },
      runtimeFilters: {
      },
      isLoading: true,
      isSubmitted: false,
      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [
        { key: 'status' },
        { key: 'subject' },
        { key: 'updated_at' },
        { key: 'actions', thStyle: { width: '190px' } },
      ],
      massEmailStatusOptions: [
        { value: '', text: 'Todos' },
        ...massEmailStatusOptions,
      ],
    }
  },
  computed: {},
  watch: {
    currentPage() {
      this.getMassEmails()
    },
  },
  methods: {
    formatDateTimeDbToView,
    reset() {
      this.status = ''
      this.subject = ''
      this.runtimeFilters = Object.fromEntries(Object.entries(this.runtimeFilters).map(item => [item[0], '']))
    },
    async getMassEmails() {
      this.$refs.rules.validate().then(async success => {
        if (!success) {
          return
        }

        this.isSubmitted = true
        this.isLoading = true

        const {
          status,
          subject,
        } = this

        try {
          const response = await MassEmailService.list({
            page: this.currentPage,
            status,
            subject,
          })
          this.results = response.data.data.massEmails.data
          this.pages = { ...response.data.data.massEmails, data: null }
        } catch (error) {
          this.$swal({
            title: 'Erro ao abrir',
            text: error.response ? Object.values(error.response.data.errors) : error.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } finally {
          this.isLoading = false
        }
      })
    },
    sortingChanged(ctx) {
      if (ctx.sortBy !== '') {
        this.filtersValues.sortBy = ctx.sortBy
        this.filtersValues.sortDesc = ctx.sortDesc
        this.getMassEmails()
      }
    },
    newMassEmail() {
      this.$router.push({ name: 'cms-mass-emails-edit', params: { massEmail: {} } })
    },
    async open(id) {
      this.isLoading = true

      try {
        const response = await MassEmailService.open({ id })
        this.$router.push({ name: 'cms-mass-emails-edit', params: { massEmail: response.data.data } })
      } catch (error) {
        this.$swal({
          title: 'Erro ao abrir',
          text: error.response ? Object.values(error.response.data.errors) : error.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        this.isLoading = false
      }
    },
    async remove(id) {
      this.isLoading = true

      try {
        await MassEmailService.remove({ id })
        await this.getMassEmails()
      } catch (error) {
        this.$swal({
          title: 'Erro ao abrir',
          text: error.response ? Object.values(error.response.data.errors) : error.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
