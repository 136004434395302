var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-actions',{ref:"filters",staticClass:"form-filters",attrs:{"title":"Filtros","action-close":false,"action-refresh":false,"action-collapse":true},on:{"refresh":function($event){return _vm.refreshStop('cardAction')}}},[_c('validation-observer',{ref:"rules"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('b-form-select',{attrs:{"id":"status","options":_vm.massEmailStatusOptions},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Assunto","label-for":"subject"}},[_c('b-form-input',{attrs:{"id":"subject"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',{staticClass:"form-buttons",staticStyle:{"margin-top":"20px"}},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" Limpar ")]),_c('b-button',{attrs:{"variant":"gradient-primary"},on:{"click":function($event){return _vm.getMassEmails()}}},[_vm._v(" Buscar ")])],1)],1)],1),_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[(_vm.isSubmitted)?_c('h2',[_vm._v(" Envios em massa ")]):_vm._e()]),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"gradient-primary"},on:{"click":_vm.newMassEmail}},[_vm._v(" Novo ")])],1)]),(_vm.isSubmitted)?_c('div',[_c('b-table',{staticClass:"mt-1",attrs:{"busy":_vm.isLoading,"fixed":"","items":_vm.results,"fields":_vm.result_fields,"sort-icon-left":"","no-local-sorting":"","sort-by":_vm.filtersValues.sortBy,"sort-desc":_vm.filtersValues.sortDesc},on:{"update:sortBy":function($event){return _vm.$set(_vm.filtersValues, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filtersValues, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filtersValues, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filtersValues, "sortDesc", $event)},"sort-changed":_vm.sortingChanged},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center table-spinner"},[_c('b-spinner',{attrs:{"label":"Loading..."}}),_c('strong',[_vm._v("Carregando...")])],1)]},proxy:true},{key:"cell(status)",fn:function(row){return [_c('b-badge',{attrs:{"variant":{
                  'editing': 'secondary',
                  'awaiting': 'info',
                  'sending': 'warning',
                  'sent': 'success',
                }[row.item.status]}},[_vm._v(" "+_vm._s(_vm.$t(("mass_email.status." + (row.item.status))))+" ")])]}},{key:"head(subject)",fn:function(){return [_vm._v(" Assunto ")]},proxy:true},{key:"head(updated_at)",fn:function(){return [_vm._v(" Última atualização ")]},proxy:true},{key:"cell(updated_at)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.formatDateTimeDbToView(row.item.updated_at))+" ")]}},{key:"head(actions)",fn:function(){return [_vm._v(" Ações ")]},proxy:true},{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"d-flex"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.open(row.item.id)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.remove(row.item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)]}}],null,false,129110807)}),(!_vm.isLoading && _vm.isSubmitted)?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('p',[_vm._v("Mostrando "+_vm._s(_vm.pages.from)+" até "+_vm._s(_vm.pages.to)+" de "+_vm._s(_vm.pages.total)+" resultados")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-pagination-nav',{attrs:{"number-of-pages":_vm.pages.last_page,"base-url":"#","align":"end"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1):_vm._e()],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }