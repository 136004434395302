import useJwt from '@/auth/jwt/useJwt'

export default class MassEmailService {
  static list = ({
    page,
    status,
    subject,
  }) => useJwt.post('/mass-emails', {}, {
    page: page ?? 1,
    status,
    subject,
  })

  static store = args => useJwt.post('/mass-emails/store', {}, args)

  static open = ({ id }) => useJwt.get(`/mass-emails/${id}/open`)

  static remove = ({ id }) => useJwt.get(`/mass-emails/${id}/remove`)
}
